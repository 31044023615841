import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/fire_logo.jpg";
import agentImg from "../../images/agent_complain.jpg";
import configImg from "../../images/configuration.png";

const extraPage = () => {
  return (
    <div className="main-section" id="home-page">
      <div className="content-section">
        <div className="">
          <div className="logo-section">
            <img src={logo} alt="" />
          </div>
        </div>
        {/* navigation bar  */}
        <div className="navigation-bar">
          <ul>
            <li>
              <Link className="nav-menu" to="/home">
                <i className="fa-solid fa-house"></i>
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/customarservice">
                <i className="fa-duotone fa-question question-icon"></i> SERVICE
              </Link>
            </li>

            <li>
              <Link className="nav-menu" to="/admin">
                <img className="configImg" src={configImg} alt="" /> ADMIN
              </Link>{" "}
              {/*<i className="fa-solid fa-user"></i>// */}
            </li>
            <li>
              <Link className="nav-menu" to="/SubAdmin">
                <img className="configImg" src={configImg} alt="" />
                SUB ADMIN
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/superagent">
                <img className="configImg" src={configImg} alt="" /> SUPER
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/onlineagent">
                <img className="configImg" src={configImg} alt="" />
                MASTER
              </Link>
            </li>
            <li>
              <Link className="nav-menu" to="/OLDNEW">
                <img className="configImg" src={configImg} alt="" />
                OLD/NEW
              </Link>
            </li>
          </ul>
        </div>

        <section className="section_bg">
          <div>
            <h1>এজেন্ট এর বিরুদ্ধে কিভাবে অভিযোগ করবেন?</h1>
            <div>
              <p>
                এজেন্ট এর সাথে আপনার যে কোন ধরনের সমস্যা থাকতে পারে। তার কিছু
                উদাহরন নিম্নে দেয়া হলোঃ
              </p>
              <p>** এজেন্ট ঠিক মত ডিপোজিট বা উইথড্র দিচ্ছে না।</p>
              <p>
                ** এজেন্ট এর সকাল ১০ টা থেকে রাত ১০ টা পর্যন্ত কাজ করার কথা।
                কিন্তু ঠিক মত করছে না।
              </p>
              <p>
                ** এজেন্ট ডিপোজিট এর টাকা নিয়েছে কিন্তু ঠিক মত ডিপোজিট দিচ্ছে
                না।
              </p>
              <p>** আপনার আইডি লক হয়ে গেছে আনলক করছে না।</p>
              <p>
                এই রকম নানা অভিযোগ আপনার থাকতে পারে এজেন্ট এর বিরুদ্ধে। এই
                অভিযোগ গুলো র সমস্যা সমাধান এর জন্য আপনাকে প্রথমে জানতে হবে
                আমাদের আপ লাইন গুলো কিভাবে কাজ করে এবং কার কাছে অভিযোগ করবেন।
              </p>
              <p>
                ** মাষ্টার এজেন্ট এর বিরুদ্ধে অভিযোগ করতে হলে আপনাকে তার সুপার
                এজেন্টের কাছে অভিযোগ করতে হবে। যে কোন মাষ্টার এজেন্ট এর সুপার
                এজেন্ট কে তা বের করতে হলে আপনাকে এজেন্টের নামে র শেষে কমপ্লেইন
                বাটন এ ক্লিক করতে হবে। নিচের ছবি তে দেখুনঃ{" "}
              </p>
              <img className="agent_img" src={agentImg} alt="" />
              <p>
                কমপ্লেইন বাটন এ ক্লিক অরলে অই মাষ্টার এজেন্ট এর সুপার এজেন্ট এর
                নাম দেখতে পারবেন। তখন আপনি অই সুপার এজেন্ট এর কাছে অই মাষ্টার
                এজেন্ট এর বিরুদ্ধে অভিযোগ করতে পারবেন। ঠিক সেই রকম ভাবে সুপার
                এজেন্ট এর নামে অভিযোগ করতে পারবেন।
              </p>
              <p>
                আপনি যদি কোন মাষ্টার এজেন্ট এর নামে অভিযোগ করতে চান – তাহলে তার
                সুপার এজেন্ট এর কাছে অভিযোগ করতে হবে। যদি সুপার এজেন্ট এর নামে
                অভিযোগ করতে চান তাহলে এডমিন এর কাছে অভিযোগ করতে হবে।
              </p>
              <p>
                যদি আপনার অভিযোগের সমাধান সুপার এজেন্ট না দিতে পারে তাহলে আপনি
                কাস্টমার সার্ভিস এডমিন এর কাছে অভিযোগ করবেন। যদি তিনিও দিতে না
                পারে তাহলে সরাসরি এডমিন কাছে অভিযোগ করবেন।
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default extraPage;
